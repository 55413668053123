import React from 'react'
import Script from 'next/script'

interface GoogleTagManagerProps {
  googleTrackingId: string
}

const GoogleTagManager: React.FC<GoogleTagManagerProps> = (
  { googleTrackingId },
) => (
  <Script
    strategy="beforeInteractive"
    dangerouslySetInnerHTML={{
      __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${googleTrackingId}');
          `,
    }}
  />
)

export default GoogleTagManager
